<template>
  <div>
    <action-header
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <shop-form @change="handleChange" />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />

    <q-dialog
      v-if="shop && shop.id"
      v-model="interactionsModal"
    >
      <q-card style="min-width: 65vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Interactions') }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section v-if="interactionsModal && shop && shop.id">
          <n-interactions
            :shop="shop"
            :search-by="'shop'"
            no-fields
          />
        </q-card-section>

        <q-card-section
          v-else
          class="alert alert-warning"
        >
          {{ $t('Shop not exist!') }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isOpenHistoryModal">
      <q-card style="min-width: 70vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section>
          <history
            v-if="isOpenHistoryModal && interaction && interaction.id"
            :id="interaction.id"
            :entity="'Orderadmin\\Notifications\\Entity\\Crm\\Interaction'"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <eav-modal ref="eavModal" @submit="handleEAVSubmit" />

    <chat-modal ref="chatModal" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'
import ShopForm from './../../components/shop-form/ShopForm'
import History from './../../components/history/history'
import ChatModal from '../../components/chat/ChatModal'
import NInteractions from '../../components/notifications/Interactions'
import EavModal from '../../components/modals/EavModal.vue'

export default {
  name: 'Shop',
  components: {
    ActionHeader,
    ShopForm,
    History,
    ChatModal,
    NInteractions,
    EavModal
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      interactionsModal: false,
      isOpenHistoryModal: false
    }
  },
  computed: {
    ...mapGetters([
      'shop',
      'appOptions',
      'isAdministrator',
      'isSupervisior',
      'currentUser'
    ]),
    page () {
      return {
        id: this.shop && this.shop.id,
        name: this.shop && this.shop.id
          ? this.$t('Store')
          : this.$t('New store')
      }
    },
    headerModel () {
      if (!this.shop) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 q-pr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.shop.id ? this.$t('Store ID: ') + this.shop.id : this.$t('New Store')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row text-center row justify-center',
          options: [
            {
              id: 'interactions',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              icon: 'phone',
              disabled: !this.shop || !this.shop.id,
              variant: 'light',
              label: this.$t('Interactions'),
              onClick: () => {
                this.interactionsModal = true
              }
            },
            {
              id: 'chat',
              type: 'button',
              icon: 'comments',
              variant: 'light',
              disabled: !this.shop || !this.shop.id,
              label: this.$t('Discussion'),
              onClick: () => {
                const entities = [
                  { entityClass: this.$entities.Orderadmin_Products_Entity_Shop, entity: this.shop.id }
                ]

                this.$refs.chatModal.open(entities, { showOrderInfo: true, handler: this.$entities.Orderadmin_Products_Entity_Shop })
              }
            },
            {
              // if: false,
              id: 'eav',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('EAV'),
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                this.$refs.eavModal.open(this.shop.eav, { entityClass: this.$entities.Orderadmin_Products_Entity_Shop })
              }
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.shop) {
      this.loadShop(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewShop()

      if (!this.isSupervisior && !this.isAdministrator) {
        const owner = JSON.parse(localStorage.getItem('userData'))
        this.updateShop({
          _embedded: {
            owner
          }
        })
      }
    }
  },
  unmounted () {
    this.setShop(null)
  },
  methods: {
    ...mapActions([
      'loadShop',
      'saveShop'
    ]),
    ...mapMutations([
      'setShop',
      'setNewShop',
      'updateShop',
      'updateShopEmbedded'
    ]),
    handleEAVSubmit (eav) {
      this.updateShop({ eav })
      this.hasChange = true
    },
    handleChange () {
      this.hasChange = true
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      
      if(!this.shop.id && !this.shop._embedded.owner){
        this.updateShopEmbedded({owner:`${this.currentUser.id}`})
      }

      // const query = {
      //   per_page: 25,
      //   page: 1,
      //   filter: [
      //     { type: 'eq', field: 'id', value: this.currentUser.id }
      //   ]
      // }
      // console.log(query)
      // this.$service.warehouse.getAll(query).then(result=>{
      //   console.log(result)

        this.isSaveLoading = true
        this.saveShop()
          .then(() => {
            this.hasChange = false
            this.handleBack()
          })
          .finally(() => {
            this.isSaveLoading = false
          })
      // })   
      
      
    }
  }
}
</script>
