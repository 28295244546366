<template>
  <div class="full-width">
    <form-builder :schema="schema" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ShopForm',
  emits: ['change'],
  data(){
    return {
      types:['shop','virtual'],
      fieldsWithError: []
    }
  },
  computed: {
    ...mapGetters([
      'shop',
      'isSupervisior',
      'isAdministrator'
    ]),
    schema () {
      let styleClasses = ''

      if (!this.shop || this.shop.id) {
        styleClasses = 'd-none'
      } else {
        styleClasses = this.shop.type === 'virtual'
          ? 'col-12 col-md-6 q-pa-sm'
          : 'col-12 q-pa-sm'
      }

      return {
        isLoading: !this.shop,
        groups: [
          {
            styleClasses: 'row',
            entity: this.$entities.Orderadmin_Products_Entity_Shop,
            entityId: this.shop && this.shop.id,
            entityState: this.shop && this.shop.state,
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.shop && this.shop.name,
                field: 'name',
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                disabled: !(this.isSupervisior || this.isAdministrator),
                onChange: name => {
                  this.updateShop({ name })
                  this.$emit('change', this.shop)
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.shop && this.shop.type,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  const text = row[0].toUpperCase() + row.slice(1).toLowerCase()
                  return this.$t(text)
                },
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                disabled: this.shop?.id,
                required: true,
                field: 'type',
                error: this.fieldsWithError.includes('type') && 'Please fill type!',
                options: this.types,
                onChange: (type) => {
                  this.updateShop({ type })
                  this.updateShopEmbedded({ owner: null })
                  // this.resetOwnerField()

                  if (type) {
                    this.removeField('type')
                  }

                  this.$emit('change', this.shop)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Parent'),
                value: this.shop && this.shop._embedded.parent,
                wrapperStyleClasses: this.shop && this.shop.type === 'virtual'
                  ? 'col-12 col-md-6 q-pa-sm'
                  : 'd-none',
                disabled: this.shop && (!this.shop.id && this.hasParent) || !!(this.shop && this.shop.id && this.cleanWarehouse?._embedded?.parent) || this.shop?.id,
                required: this.type === 'virtual',
                field: 'parent',
                error: this.fieldsWithError.includes('parent') && 'Please fill fulfillment facility!',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active','blocked'] }
                    ]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: (parent) => {
                  this.updateBaseData(parent)

                  if (parent) {
                    this.removeField('parent')
                  }

                  this.$emit('change', this.shop)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Owner'),
                field: 'owner',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                value: this.shop && this.shop._embedded.owner,
                disabled: this.shop?.id,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        type: 'orx',
                        where: 'or',
                        conditions: [
                          {
                            field: 'id',
                            alias: 'r',
                            type: 'eq',
                            value: 8
                          },
                          {
                            type: 'eq',
                            alias: 'r',
                            field: 'id',
                            value: 29
                          }
                        ]
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: owner => {
                  this.updateShopEmbedded({ owner })
                  this.$emit('change', this.shop)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateShop',
      'updateShopEmbedded'
    ]),
    updateBaseData (parent) {
      if (!parent._embedded) {
        parent._embedded = {}
      }

      this.updateShopEmbedded({
        parent,
        country: parent._embedded.country || this.getValue(parent.country),
        currency: parent._embedded.currency || this.getValue(parent.currency),
        length: parent._embedded.length || this.getValue(parent.length),
        weight: parent._embedded.weight || this.getValue(parent.weight)
      })
    },
    removeField (field) {
      this.fieldsWithError = this.fieldsWithError.filter(x => x !== field)
    },
    getValue (val) {
      return typeof val === 'string'
        ? JSON.parse(val)
        : val
    },
  }
}
</script>
